@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

@import "themes";

@import "@nebular/theme/styles/globals";
@import "@nebular/auth/styles/globals";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";

@import "./pace.theme";

@import "./layout";
@import "./overrides";

@include nb-install() {
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  @include ngx-pace-theme();

  @include nb-overrides();
}

button:focus {
  outline: none;
}

.stat-card {
  background: white !important;
  text-align: center !important;
  cursor: pointer !important;
  margin-top: 10px !important;
}

nb-menu {
  .menu-item a[title="Maps"] {
    pointer-events: none !important;
    cursor: not-allowed !important;
    opacity: 0.6;

    .menu-title,
    nb-icon {
      color: #8f9bb3 !important;
    }

    * {
      pointer-events: none !important;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
      cursor: not-allowed !important;
    }
  }

  a[href="#"],
  a[href="javascript:void(0)"] {
    &.menu-item,
    .menu-item {
      pointer-events: none !important;
      cursor: not-allowed !important;
    }
  }
}

nb-accordion {
  box-shadow: none !important;
}
.custom-toast {
  margin-top: 90px !important;
  border-radius: 12px !important;
}

.legend {
  background: transparent !important;
}

* {
  font-family: "Rajdhani", Arial, Helvetica, sans-serif !important;
}

nb-menu .menu-item a.active {
  color: #4f9fcf;
}
nb-menu .menu-item a.active .menu-icon {
  color: #4f9fcf;
}

nb-menu .menu-item a:hover {
  color: #4f9fcf;
}

nb-menu .menu-item a:hover .menu-icon {
  color: #4f9fcf;
}

nb-sidebar.compacted nb-menu .menu-item a.active::before {
  background: #4f9fcf;
}

nb-layout .layout .layout-container .content .columns nb-layout-column {
  padding: 0;
}

.scrollable {
  background-color: #ffffff !important;
}

.menu-sidebar {
  z-index: 9999 !important;
  background-color: #ffffff !important;
}

.header-container {
  z-index: 10000 !important;
}

.leaflet-control-zoom {
  z-index: 10 !important;
  background-color: red !important;
}

.fixed {
  z-index: 10000 !important;
}

.fixed-bar {
  z-index: 1000 !important;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  z-index: 50 !important;
}

.p-component {
  padding-top: 10px !important;
  width: 100% !important;
  border-radius: 20px !important;
}

.ng2-smart-sort-link {
  font-weight: 700 !important;
}

.ng-star-inserted {
  font-weight: 600 !important;
}

ng2-smart-table nav.ng2-smart-pagination-nav .pagination li.active a,
ng2-smart-table nav.ng2-smart-pagination-nav .pagination li.active a:hover,
ng2-smart-table nav.ng2-smart-pagination-nav .pagination li.active a:focus,
ng2-smart-table nav.ng2-smart-pagination-nav .pagination li.active > span,
ng2-smart-table nav.ng2-smart-pagination-nav .pagination li.active > span:hover,
ng2-smart-table
  nav.ng2-smart-pagination-nav
  .pagination
  li.active
  > span:focus {
  background-color: #9cd3db !important;
}

.ng-star-inserted {
  justify-content: center !important;
}

ng2-smart-table nav.ng2-smart-pagination-nav .pagination li a,
ng2-smart-table nav.ng2-smart-pagination-nav .pagination li > span {
  color: gray !important;
}

[nbInput].size-medium {
  background-color: transparent !important;
  width: 230px !important;
}

.panel-container[_ngcontent-rrv-c232] .p-panel .p-panel-header {
  margin: 1rem !important;
  border-radius: 12px !important;
}

swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  background-color: red !important;
  margin-top: 20px !important;
  position: relative !important;
}
